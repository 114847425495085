import React from 'react'
import { Helmet } from 'react-helmet'


const SEO = ({
  title,
  description,
  image,
  schema
}: {
  title: string
  description?: string
  image?: string
  schema?: any
}) => {

  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>{title}</title>
        <meta name="p:domain_verify" content="34d0b11331b8abbd1b476ca937426972" />
        <meta name="google-site-verification" content="UOTijPDraZIlpFf0mzc-LJSNKYfH4mhlp-tnebOQQh8" />
        <meta name="facebook-domain-verification" content="c9iudrrr5fh9pips698t98uid9a7m0" />
        <meta name='description' content={description} />
        {image && <meta name='image' content={`https:${image}`} />}
        <meta property='og:title' content={title} />
        <meta property='og:description' content={description} />
        {image && <meta property='og:image' content={`https:${image}`} />}
        {schema && <script type='application/ld+json'>{JSON.stringify(schema)}</script>}
      </Helmet>
    </>
  )
}

export default SEO
